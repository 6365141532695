// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    blank: `${ROOTS.DASHBOARD}/blank`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    event: `${ROOTS.DASHBOARD}/event`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
    },
    sponsor: {
      root: `${ROOTS.DASHBOARD}/sponsor`,
    },
    veteran: {
      root: `${ROOTS.DASHBOARD}/veteran`,
    },
    community: {
      root: `${ROOTS.DASHBOARD}/community`,
    },
    donor: {
      root: `${ROOTS.DASHBOARD}/donor`,
    },
    sign: {
      root: `${ROOTS.DASHBOARD}/sign`,
    },
    shirt: {
      root: `${ROOTS.DASHBOARD}/shirt`,
    },
    pictures: {
      root: `${ROOTS.DASHBOARD}/pictures`,
    },
    devices: {
      root: `${ROOTS.DASHBOARD}/devices`,
    },
    golfteam: {
      root: `${ROOTS.DASHBOARD}/golfteam`,
      newTeam: `${ROOTS.DASHBOARD}/golfteam/newteam`,
    },
    people: {
      view: (type, id, role) => `${ROOTS.DASHBOARD}/people/${type}/${id}/${role}`,
      newsponsor: `${ROOTS.DASHBOARD}/people/newsponsor`,
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      sponsorship: {
        list: `${ROOTS.DASHBOARD}/product/sponsorship/list`,
        new: `${ROOTS.DASHBOARD}/product/sponsorship/new`,
        edit: (id) => `${ROOTS.DASHBOARD}/product/sponsorship/${id}/edit`,
      },
      veteran: {
        list: `${ROOTS.DASHBOARD}/product/veteran/list`,
        new: `${ROOTS.DASHBOARD}/product/veteran/new`,
        edit: (id) => `${ROOTS.DASHBOARD}/product/veteran/${id}/edit`,
      },
      community: {
        list: `${ROOTS.DASHBOARD}/product/community/list`,
        new: `${ROOTS.DASHBOARD}/product/community/new`,
        edit: (id) => `${ROOTS.DASHBOARD}/product/community/${id}/edit`,
      },
      sign: {
        list: `${ROOTS.DASHBOARD}/product/sign/list`,
        new: `${ROOTS.DASHBOARD}/product/sign/new`,
        edit: (id) => `${ROOTS.DASHBOARD}/product/sign/${id}/edit`,
      },
      shirt: {
        list: `${ROOTS.DASHBOARD}/product/shirt/list`,
        new: `${ROOTS.DASHBOARD}/product/shirt/new`,
        edit: (id) => `${ROOTS.DASHBOARD}/product/shirt/${id}/edit`,
      },
    },
  },
};
